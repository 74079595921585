import React from 'react';
import { Route } from 'react-router-dom'
import FrontPage from './FrontPage'

const SiteRouter = props => {
    return (
        <div>
            <Route exact path="/" component={FrontPage} />
            <Route path="/lars-nelson" component={FrontPage} />
        </div>
    )
}

export default SiteRouter