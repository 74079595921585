import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import GitHubIcon from '@material-ui/icons/GitHub';

// IMAGE
import PGSchools from '../Images/InUse/PGSchoolEdit2.png'

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 100,
    },
    pos: {
        marginBottom: 12,
    },
});

const PlanAhead = props => {

    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={PGSchools}
                        title="Plan Ahead logo"
                    />
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Plan Ahead
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            May 2020
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            An online school website with different tools for teachers and students.
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <a href="https://github.com/MisterDaviso/pugetsound-schools-server" target="_blank" rel='noreferrer'>
                        <Button size="small" color="primary">
                            <GitHubIcon />
                            Server Code
                        </Button>
                    </a> 
                    <a href="https://github.com/ObtuseSpruce/pugetsound-client" target="_blank" rel='noreferrer'>
                        <Button size="small" color="primary">
                            <GitHubIcon />
                            Client Code 
                        </Button>
                    </a> 
                </CardActions>
            </Card>
        </Grid>
    )
}

export default PlanAhead