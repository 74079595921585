import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider';

import Resume from '../../Static/Resume.pdf'

// ICONS
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import DownloadIcon from '@material-ui/icons/ArrowDownward'
import DescriptionIcon from '@material-ui/icons/Description';
import { Icon } from '@iconify/react';
import codewarsIcon from '@iconify-icons/simple-icons/codewars';


const useStyles = makeStyles((theme) => ({
    sections: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    indent: {
        marginLeft: 20,
    },
    buffer: {
        marginTop: 10
    },
    minorBuffer: {
        marginTop:5,
        marginLeft:10
    },
    space: {
        marginRight: 20,
    }
}));

const Contact = props => {

    const classes = useStyles();

    const content = [
        { name: 'GitHub', icon: <GitHubIcon />, url: 'https://github.com/MisterDaviso'},
        { name: 'CodeWars', icon: <Icon icon={codewarsIcon} style={{ fontSize: '24px' }}/>, url: 'https://www.codewars.com/users/MisterDaviso'},
        { name: 'LinkedIn', icon: <LinkedInIcon />, url: 'https://www.linkedin.com/in/lars-nelson-392084150/'},
    ]

    return (
        <Grid item xs={12} md={12} >
            <Typography variant="h4" >
                Contact
            </Typography>
            <Divider />
            <Typography className={classes.indent}>
                <Box fontSize="h6.fontSize" fontWeight="fontWeightBold" className={classes.buffer}>
                    Direct Contact
                </Box>
                <Grid container direction="row" spacing={1} alignItems="center" className={classes.minorBuffer}>
                    <Grid item className={classes.space}> Phone: <a href={`tel:2532251796`}>253-225-1796</a> </Grid>
                    <Grid item> Email: <a href={`mailto:lnelson7820@gmail.com`}>lnelson7820@gmail.com</a> </Grid>
                </Grid>
                <Box id='Contact' fontSize="h6.fontSize" fontWeight="fontWeightBold" className={classes.buffer}>
                    Social Networks
                </Box>
                <Grid container direction="row" spacing={1} alignItems="center" className={classes.minorBuffer}>
                    {content.map((network) => {
                        return (
                            <a href={network.url} target="_blank" rel='noreferrer'>
                                <Grid container direction="row" spacing={1} alignItems="center" className={classes.space}>
                                    <Grid item> {network.icon} </Grid>
                                    <Grid item>{network.name}</Grid>
                                </Grid>
                            </a>
                        )
                    })}
                </Grid>
            </Typography>
        </Grid>
    )
}

export default Contact