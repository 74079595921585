import React from 'react';

// MATERIAL UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

// CONTENT
import Header from './Components/Header'
import Footer from './Components/Footer'
import Blog from './Blog/Blog'

// STYLES
const useStyles = makeStyles((theme) => ({
    namePlate: {
        marginTop: theme.spacing(8)
    },
    mainGrid: {
        marginTop: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        textAlign: 'center',
        padding: theme.spacing(2)
    },
    topBuffer: {
        marginTop: 20
    }
}));

const FrontPage = props => {
    
    const classes = useStyles();
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    let bigName = <Typography
        component="h2"
        variant="h2"
        color="inherit"
        align="center"
        noWrap
        className={classes.namePlate}
        id="Top"
    >
        <Box fontFamily="Monospace" fontWeight="fontWeightMedium"> LARS NELSON </Box>
    </Typography>

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Header />
                <main>
                    {largeScreen ? bigName : <Divider className={classes.topBuffer}/> }
                    <Grid container spacing={1} className={classes.mainGrid}>
                        <Grid item xs={1} sm={1} md={2} />
                        <Blog /> 
                        <Grid item xs={1} sm={1} md={2} />
                    </Grid>
                </main>
            </Container>
            <Footer title="Lars Nelson" description="Thank you for your consideration!" />
        </React.Fragment>
    );
}

export default FrontPage