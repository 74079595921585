import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import GifIcon from '@material-ui/icons/Gif';


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 100,
    },
    pos: {
        marginBottom: 12,
    },
});

const UBBVolunteer = props => {
    
    const classes = useStyles();
    
    return (
        <Grid item xs={12} sm={6}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image="https://media.giphy.com/media/yvCqPUwMMPizB1xQXa/giphy.gif"
                        title="UBB site demo"
                    />
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            UBB Information Manager
                        </Typography>
                        <Typography gutterBottom color="textSecondary">
                            Aug 2020 - Feb 2021
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            A web application used to organize both general and sensitive information
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <a href="https://media.giphy.com/media/yvCqPUwMMPizB1xQXa/giphy.gif" target="_blank" rel='noreferrer'>
                        <Button size="small" color="primary">
                            <GifIcon />
                            Demonstration video
                        </Button>
                    </a> 
                </CardActions>
            </Card>
        </Grid>
    )
}

export default UBBVolunteer