import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider';
import DescriptionIcon from '@material-ui/icons/Description';
import Resume from '../../Static/Resume.pdf'


const useStyles = makeStyles((theme) => ({
    sections: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    indent: {
        marginLeft: 20,
    },
    buffer: {
        marginBottom: 20
    }
}));

// I am a software engineer with a passion for creating cohesive, modular, and practical systems of logic. I ensure my work is not just functional, but conceptually sound. This is a skill that allows me to parse through old systems easily and adapt to new technologies quickly. I intend to work at the forefront of new technologies, both for the challenge it brings and the potential it provides.
//I am a software engineer who specializes in creating practical and logical systems of code. I adapt to new systems with ease, allowing me to parse through old infrastructure and quickly adapt to new technologies. My intention is to develop new technologies, both for the challenge that brings and the potential that it provides.

const About = () => {
    const classes = useStyles();
    
    return (
        <Grid item xs={12} md={12} id='About'>
            <Typography variant="h4" >
                About
            </Typography>
            <Divider className={classes.buffer}/>
                
            <Typography className={classes.indent} >
                <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
                    Who I Am
                </Box>
                <Box fontSize={15} fontWeight="fontWeightLight" className={classes.buffer}>
                    I am a software engineer who specializes in creating backend code. I am adept at understanding the underlying logic behind existing code, which allows me to update it if necesary. I am also always brushing up on my skills independantly, for example I am currently taking an 11-week course in machine learning.
                </Box>
                <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
                    Areas of Work
                </Box>
                <Box fontSize={15} fontWeight="fontWeightLight" className={classes.buffer}>
                    I am experienced in full-stack web development with a focus in backend systems and database management.
                </Box>
                <Box fontSize="h6.fontSize" fontWeight="fontWeightBold" className={classes.buffer}>
                    <a href={Resume} target="_blank" rel='noreferrer'>
                        <Grid container direction="row" spacing={1} alignItems="center" className={classes.space}>
                            <Grid item> Resume </Grid>
                            <Grid item> <DescriptionIcon/> </Grid>
                        </Grid>
                    </a>
                </Box>
            </Typography>
        </Grid>
    )
}

export default About
