import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
    sections: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    buffer: {
        marginBottom: 20
    },
}));

const Experience = props => {

    const classes = useStyles();

    return (
        <Grid item xs={12} md={12} id='Experience' >
            <Typography variant="h4">
                Experience
            </Typography>
            <Divider className={classes.buffer}/>
            <Card className={classes.buffer}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        University Behind Bars Software Volunteer
                    </Typography>
                    <Typography color="textSecondary">
                        Aug 2020 - Feb 2021
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Developed an application alongside a team to assist the staff of UBB in the tracking and organization of both general and sensitive information.
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.buffer}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        General Assembly Student
                    </Typography>
                    <Typography color="textSecondary">
                        Mar 2020 - Jun 2020
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Full-Stack Web Software Development program with more than 450 hours over 12 weeks
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Experience