import React from 'react';

// MATERIAL UI 
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';

// CONTENT
import About from './About'
import Experience from './Experience'
import Projects from '../Projects/Projects'
import Contact from './Contact'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
}));

const Blog = props => {
    
    const classes = useStyles();
    
    return (
        <Grid item xs={12} sm={10} md={8}>
            <Paper className={classes.root} >
                <About />
                <Projects />
                <Experience />
                <Contact />
            </Paper>
        </Grid>
    );
}

export default Blog