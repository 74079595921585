import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import { scroller } from 'react-scroll'


const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(0,'auto')
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(0,5),
        flexShrink: 0,
    },
    title: {
        flexGrow: 1,
    },
}));

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}


export default function Header(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const sections = ['Top', 'About', 'Projects', 'Experience', 'Contact']
    //const sectionsSmall = ['Social', 'About', 'Projects', 'Contact']

    let scrollToSection = (section) => {
        scroller.scrollTo((section), {
            duration: 1000,
            smooth: true,
            offset: -60,
        })
    }

    if (largeScreen)
        return (
            <React.Fragment>
                <ElevationScroll {...props}>
                    <AppBar>
                        <Toolbar component="nav" variant="dense" className={classes.toolbar}>
                            {sections.map((section) => (
                                <Button color="inherit" className={classes.toolbarLink} onClick={() => scrollToSection(section)}>
                                    {section}
                                </Button>
                            ))}
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
            </React.Fragment>
        );
    else
        return (
            <React.Fragment>
                <ElevationScroll {...props}>
                    <AppBar>
                        <Toolbar component="nav" variant="dense" >
                            <div>
                                <Button
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >
                                    <MenuIcon />
                                </Button>
                                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList autoFocusItem={open} id="menu-list-grow" >
                                                        {sections.map((section) => (
                                                            <MenuItem onClick={() => scrollToSection(section)}>
                                                                {section}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                            <Typography variant="h6" className={classes.title}>
                                Lars Nelson
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
            </React.Fragment>
        )
}
