import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Divider } from '@material-ui/core/'

import { Element } from 'react-scroll'

import UndergroundCombat from './Posts/UndergroundCombat'
import PersonalizedTweets from './Posts/PersonalizedTweets'
import PlanAhead from './Posts/PlanAhead'
import TeachMachinesToCheck from './Posts/TeachMachinesToCheck'
import UBBVolunteer from './Posts/UBB'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    sections: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
}))

const GaProjects = props => {
    const classes = useStyles()
    
    return (
        <Grid item xs={12} md={12} id='Projects' >
            <Typography variant="h4" >
                Projects
            </Typography>
            <Divider />
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.root}
            >
                <UBBVolunteer />
                <TeachMachinesToCheck />
                <PlanAhead />
                <PersonalizedTweets />
            </Grid>
        </Grid>
    )
}

export default GaProjects